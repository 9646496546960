import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const invalidTokenResponse = "Invalid token provided. Please reauthenticate.";

class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    return error ? (
      <div style={{ color: "red" }}>Aconteceu um erro: {error.message}</div>
    ) : (
      this.props.children
    );
  }
}

const checkForErrors = ({
  errors,
  records = [],
  record = {},
  isLoaded,
  isCreate = false,
}) => {
  const noRecord =
    !record || (!records?.length && !!record && !Object.keys(record)?.length);

  if (!isLoaded)
    return (
      <CircularProgress
        color="secondary"
        size={50}
        style={{ margin: "auto" }}
      />
    );
  if (errors)
    return <Typography style={{ marginLeft: 30 }}>{errors}</Typography>;
  if (!isCreate && noRecord)
    return (
      <div style={{ marginLeft: 30 }}>
        <Typography variant="h5">Sem registos ou não tem permissões</Typography>
      </div>
    );
  return false;
};

const requiredErrorMessage = "Este campo é obrigatório";
const emailErrorMessage = "Por favor preencha um endereço de email válido";
const nifErrorMessage = "Por favor introduza um NIF válido";
const ibanErrorMessage =
  "Este IBAN é inválido. Por favor introduza um IBAN válido";
const zipPortugalErrorMessage = "Este código postal é inválido em Portugal"
const zipErrorMessage = "Este campo é obrigatório quando preenche algum campo relativo à morada"
const minCharErrorMessage = (minChar) =>
  `Este campo tem de conter pelo menos ${minChar} caracteres`;

const SameNifStudentErrorMessage = ({ studentId, studentName }) => (
  <>
    <span>
      Este NIF está associado ao aluno &nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://puzzle.centrostalento.pt/alunos/${studentId}`}
        style={{ color: "#148FF6" }}
      >
        {studentName}
      </a>
      .
    </span>
    <br />
    <span>Certifique-se que é o aluno que quer associar a este contrato.</span>
    <br />
  </>
);

const SameStudentEmailErrorMessage = ({ id, name }) => (
  <>
    <span>
      Este Email está associado ao aluno &nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://puzzle.centrostalento.pt/alunos/${id}`}
        style={{ color: "#148FF6" }}
      >
        {name}
      </a>
      .
    </span>
    <br />
    <span>Certifique-se que é o aluno que quer associar a este contrato.</span>
    <br />
    <b>Certifique-se que o NIF deste contrato também pertence a este aluno.</b>
    <br />
  </>
);

const NotPortugueseNifMessage = () => (
  <>
    <span style={{ color: "tomato" }}>
      Este NIF não é português ou é inválido.{" "}
    </span>
    <span>
      <br />
      Poderá guardar os dados, mas antes <br />
      <u>certifique-se que o estudante não tem um NIF português</u>
      <br /> para evitar fraudes e erros no puzzle.
    </span>
    <br />
  </>
);

export {
  invalidTokenResponse,
  ErrorBoundary,
  checkForErrors,
  requiredErrorMessage,
  minCharErrorMessage,
  emailErrorMessage,
  nifErrorMessage,
  ibanErrorMessage,
  zipPortugalErrorMessage,
  zipErrorMessage,
  SameNifStudentErrorMessage,
  SameStudentEmailErrorMessage,
  NotPortugueseNifMessage,
};
