import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { requiredErrorMessage } from "../../../../utils/errors";
import validate from "./validate";

const useStyle = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const AddressField = (props) => {
  const classes = useStyle();
  const { field: fieldProps, control, recordValues, disabled } = props;
  const { getValues } = useFormContext();

  const addressFields = [
    {
      name: "Rua",
      key: `${fieldProps.key}[0][street]`,
      value: recordValues.street || "",
      disabled
    },
    {
      name: "Código Postal",
      key: `${fieldProps.key}[0][zip]`,
      value: recordValues.zip || "",
      disabled
    },
    {
      name: "Localidade",
      key: `${fieldProps.key}[0][city]`,
      value: recordValues.city || "",
      disabled
    },
    {
      name: "País",
      key: `${fieldProps.key}[0][country]`,
      value: recordValues.country || "",
      disabled: fieldProps.disableCountry
    }
  ];

  const rules = (subKey) => ({
    required: { value: fieldProps.requiredValue, message: requiredErrorMessage },
    validate: validate({
      fieldProps,
      getValues,
      subKey
    })
  })

  return addressFields.map((mappedField) => {
    return (
      <TableRow key={mappedField.key}>
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Controller
            control={control}
            defaultValue={mappedField.value || ""}
            name={mappedField.key}
            rules={rules(mappedField.key)}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextField
                  {...field}
                  error={!!error}
                  required={fieldProps.required}
                  helperText={error?.message}
                  className={classes.inputField}
                  name={mappedField.key}
                  label={mappedField.name}
                  variant="outlined"
                  size="small"
                  id={field.name}
                  disabled={mappedField.disabled}
                />
              );
            }}
          />
        </TableCell>
      </TableRow>
    );
  });
};

export default AddressField;
