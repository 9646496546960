import { zipErrorMessage, zipPortugalErrorMessage } from "../../../../utils/errors";
import { billingCountries } from "../SelectConnectedField/validate/billingCountries";

const validate = ({
    fieldProps,
    getValues,
    subKey
}) => ({
    zip: (value) => {
        const key = fieldProps.key
        const addressFields = [`${key}[0][street]`, `${key}[0][zip]`, `${key}[0][city]`, `${key}[0][country]`];

        const isAnyAdressFieldFilled = addressFields.some(el => !!getValues(el))
        if (!isAnyAdressFieldFilled) return true

        if (subKey !== `${key}[0][zip]`) return true;
        const isZipRequired = fieldProps.mandatoryZip

        const relatedCountryField = billingCountries.find(el => el.related === key)
        if (!relatedCountryField) return isZipRequired ? !!value || zipErrorMessage : true

        const relatedCountryValue = relatedCountryField?.field && getValues(relatedCountryField?.field)
        const isPortugal = relatedCountryValue?.identifier.toLowerCase().includes('portugal')
        if (!isPortugal) return isZipRequired ? !!value || zipErrorMessage : true

        const regex = new RegExp(/\d{4}-\d{3}/)
        const match = regex.test(value)

        return match || zipPortugalErrorMessage
    }

})

export default validate;
