import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../assets/images/logo_centrostalento.png";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansItalic from "../../assets/fonts/OpenSans-Italic.ttf";
import { documentStyles } from "./documentStyles";
import moment from "moment";

export const partnershipProtocol = (dynamicData = "") => {
  const {
    partnerName,
    partnerAddress,
    partnerNif,
    partnerResponsiblePerson,
    course,
    internshipHoursDuration,
  } = dynamicData;

  return (
    <Document size="A4">
      <Page style={styles.body}>
        <Image style={styles.image} src={Logo} />
        <Text style={styles.title}>PROTOCOLO DE PARCERIA</Text>
        <Text style={styles.boldText}>Considerando que:</Text>
        <Text style={styles.text}>
          A TALENTO - FORMAÇÃO pretende recorrer à colaboração da{" "}
          <Text>{partnerName}</Text> para promover uma aproximação, realista e
          eficaz, dos seus formandos, à prática profissional;
          {"\n"}
          Assim, é de todo o interesse para as partes estreitar relações entre
          ambas e aproveitar sinergias que permitam promover conjuntamente e
          potenciar a formação abrangente de novos profissionais, dotados de
          capacidade técnica e de adequadas aptidões de concretização prática,
          pelo que,
        </Text>
        <Text style={styles.boldText}>entre:</Text>
        <Text style={styles.text}>
          <Text style={styles.boldText}>Primeira Outorgante:</Text>{" "}
          <Text>{partnerName}</Text>, com sede em <Text>{partnerAddress}</Text>,
          e Número de Identificação de Pessoa Coletiva <Text>{partnerNif}</Text>
          , representada por <Text>{partnerResponsiblePerson}</Text> com plenos
          poderes para o ato, doravante designada como primeira outorgante.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.boldText}>Segunda Outorgante:</Text>{" "}
          TALENTODISSEIA, com sede na Rua Formosa, 349, 2º andar 4000-252 Porto 
          e Número de Identificação de Pessoa Coletiva 509 777
          970, representada por Paula Janete Pereira do Couto, na qualidade de
          Coordenadora do Departamento de Empresas e Gestão de Carreira, com
          plenos poderes para o ato, doravante designada como segunda
          outorgante.
          {"\n"}
          {"\n"}
          Acordam, tendo em vista uma aproximação reciprocamente benéfica,
          livremente e de boa fé, celebrar o presente contrato de estágio nos
          seguintes termos:
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Primeira</Text>
          <Text>(Âmbito)</Text>
        </View>
        <Text style={styles.text}>
          O presente Protocolo visa definir os termos da colaboração entre os
          outorgantes, tendo esta como objetivo principal a promoção de estágios
          curriculares pela Primeira Outorgante para os formandos da Segunda
          Outorgante.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Segunda</Text>
          <Text>(Compromissos da Primeira Outorgante)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          1. A Primeira Outorgante compromete-se a aceitar formandos (número a
          definir pela Primeira Outorgante de acordo com as suas necessidades)
          da Segunda Outorgante para a realização de estágios curriculares.
          {"\n"}
          2. A Primeira Outorgante obriga-se a realizar os respetivos estágios
          curriculares com uma duração aproximada de{" "}
          <Text>{internshipHoursDuration}</Text> horas.
          {"\n"}
          3. A primeira outorgante compromete-se a preencher o documento de
          avaliação final do estágio curricular que se encontra na posse dos
          formandos, sendo, o respetivo preenchimento, obrigatório para a
          atribuição de uma nota final e para o encerramento do processo de
          estágio.
          {"\n"}
          4. A Primeira Outorgante poderá divulgar o logo corporativo e o
          website da Segunda Outorgante, em todas as comunicações em que se
          mencionem parceiros.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Terceira</Text>
          <Text>(Compromissos da Segunda Outorgante)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          1. A Segunda Outorgante compromete-se a formar os futuros estagiários
          na área de <Text>{course}</Text> para que os mesmos tenham os
          conhecimentos necessários para desempenharem, com competência, um
          estágio curricular nas instalações da Primeira Outorgante.
          {"\n"}
          2. Os formandos da Segunda Outorgante estão assegurados com um seguro
          de acidentes pessoais (apólice n.º 8 252 528. Seguro da Lusitânia
          Seguros), até ao final do estágio curricular.
          {"\n"}A Segunda Outorgante poderá divulgar o logo corporativo e o
          website da Primeira Outorgante, em todas as comunicações em que se
          mencionem parceiros.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Quarta</Text>
          <Text>(Processo de admissão dos estagiários)</Text>
        </View>
        <Text style={styles.text}>
          A Segunda Outorgante, por indicação de dia e hora por parte da
          Primeira Outorgante, compromete-se a enviar os possíveis estagiários
          às instalações desta, para a realização de uma entrevista.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Quinta</Text>
          <Text>(Remuneração dos estagiários)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          Não está prevista qualquer remuneração ou contrapartida financeira ao
          estagiário, salvo deliberação em contrário da Primeira Outorgante.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Sexta</Text>
          <Text>(Tratamento dos dados pessoais do estagiário)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          1. As Outorgantes são Responsáveis Conjuntos pelo tratamento de dados
          pessoais, nos termos do art.º 26.º do Regulamento (UE) 2016/679 do
          Parlamento Europeu e do Conselho de 27 de abril de 2016 (“Regulamento
          Geral da Proteção de Dados” ou “RGPD”), porquanto determinam
          conjuntamente as finalidades e os meios do tratamento dos dados
          pessoais dos seus titulares.
          {"\n"}
          2. Os tipos de dados, que serão tratados exclusivamente para
          concretizar o objeto do protocolo de parceria serão{" "}
          <Text style={styles.boldText}>
            elementos de identificação e credenciais de estágio curricular{" "}
          </Text>
          , designadamente: nome de estagiários, identificação civil, função
          desempenhada durante o estágio, endereço de e-mail e contactos
          telefónicos.
          {"\n"}
          3. A Primeira Outorgante compromete-se a não tratar dados pessoais
          obtidos no âmbito do presente protocolo para qualquer outra finalidade
          para além da respeitante ao mesmo.
          {"\n"}
          4. As Outorgantes assumem a responsabilidade perante os titulares de
          dados cujos dados pessoais as mesmas tratem, sendo responsáveis únicas
          pela forma como os dados são tratados na sua esfera e por eventuais
          violações de dados que ocorram, não podendo responsabilizar a
          Outorgante não infratora, salvo se existindo responsabilidade
          comparticipada.
          {"\n"}
          5. Obrigam-se também as Outorgantes a assegurar o exercício dos
          direitos do titular dos dados e a cumprir os deveres de informação a
          que está obrigada, em especial a fornecer um ponto de contacto para o
          efeito.
          {"\n"}
          6. Uma vez que os titulares de dados têm a faculdade de exercer os
          respetivos direitos em relação a cada uma das Outorgantes, nos termos
          do art.º 26.º, n.º 3 do RGPD, se aqueles o fizerem junto da Primeira
          Outorgante, esta informará a Segunda Outorgante.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Sétima</Text>
          <Text>(Interpretação e Integração)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          1. Quaisquer dúvidas de interpretação do presente protocolo serão
          dirimidas por acordo entre ambas as partes.
          {"\n"}
          2. Na circunstância de se verificar um ponto omisso, a situação será
          resolvida por acordo entre ambas as partes, sendo este acordo norteado
          pelo disposto no artigo 239. ° do Código Civil.
        </Text>
        <View style={styles.contractItems}>
          <Text>Cláusula Oitava</Text>
          <Text>(Vigência)</Text>
        </View>
        <Text style={styles.contracItemsDescription}>
          1. O presente Protocolo é válido a partir do momento da sua
          assinatura, tendo a duração de um ano, automaticamente renovável,
          salvo se denunciado por algum dos outorgantes por via de carta
          registada, enviada à contraparte com três meses de antecedência.
          {"\n"}
          2. O Protocolo pode ser alvo de revisão do clausulado, no final de
          cada período de vigência, requerendo-se para tal o acordo entre as
          outorgantes.
        </Text>
        <View style={styles.text}>
          <Text style={styles.text}>
            _____________________, _____ de _______________ de{" "}
            <Text>{moment().format("YYYY")}</Text>
          </Text>
          <Text style={styles.text}>
            Feito em duas vias, devidamente rubricadas e assinadas, de igual
            valor e conteúdo.
          </Text>
          <Text style={styles.text}>
            PRIMEIRA OUTORGANTE:
            {"\n"}
            {"\n"}
            ___________________________________________________________________________
            {"\n"}
            (Representante da Primeira Outorgante)
          </Text>
          <Text style={styles.text}>
            SEGUNDA OUTORGANTE:
            {"\n"}
            {"\n"}
            ___________________________________________________________________________
            {"\n"}
            (Representante da Segunda Outorgante)
          </Text>
        </View>
        <Text
          style={styles.pagination}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

// Register font => to refactory later
Font.register({ family: "OpenSansRegular", src: OpenSansRegular });
Font.register({ family: "OpenSansBold", src: OpenSansBold });
Font.register({ family: "OpenSansItalic", src: OpenSansItalic });

const styles = StyleSheet.create(documentStyles);
