import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import Logo from '../../assets/images/logo_centrostalento.png'
import OpenSansRegular from '../../assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../assets/fonts/OpenSans-Bold.ttf'
import OpenSansItalic from '../../assets/fonts/OpenSans-Italic.ttf'
import { documentStyles } from './documentStyles';
import moment from 'moment';



export const internshipContractPartner = (dynamicData = "") => {

    const {
        partnerName,
        studentName,
        studentNif,
        studentAdress,
        courseName,
        partnerAddress,
        partnerNif,
        partnerResponsiblePerson,
        internshipHoursDuration,
        internshipStartDate
    } = dynamicData

    return (
        <Document size="A4">
            <Page style={styles.body}>
                <Image style={styles.image} src={Logo} />
                <Text style={styles.title}>
                    CONTRATO DE ESTÁGIO
                </Text>
                <Text style={styles.boldText}>
                    Considerando que:
                </Text>
                <Text style={styles.text}>
                    A TALENTO - FORMAÇÃO pretende recorrer à colaboração da <Text>{partnerName}</Text> para promover uma aproximação, realista e eficaz do/a formando/a <Text>{studentName}</Text> com o Número de Identificação Fiscal <Text>{studentNif}</Text> , do Curso de <Text>{courseName}</Text> à prática profissional, doravante mencionada como formando/a, pelo que
                </Text>
                <Text style={styles.boldText}>
                    entre:
                </Text>
                <Text style={styles.text}>
                    <Text style={styles.boldText}>Primeira Outorgante:</Text> <Text>{partnerName}</Text> com sede em <Text>{partnerAddress}</Text> e Número de Identificação de Pessoa Coletiva <Text>{partnerNif}</Text> representada por <Text>{partnerResponsiblePerson}</Text> com plenos poderes para o ato, doravante designada como primeira outorgante.
                </Text>
                <Text style={styles.text}>
                    <Text style={styles.boldText}>Segunda Outorgante:</Text> TALENTODISSEIA, com sede na Rua Formosa, 349, 2º andar - 4000-252 Porto e Número de Identificação de Pessoa Coletiva 509 777 970, representada por Paula Janete Pereira do Couto, na qualidade de Coordenadora do Departamento de Empresas e Gestão de Carreira, com plenos poderes para o ato, doravante designada como segunda outorgante.
                </Text>
                <Text style={styles.text}>
                    <Text style={styles.boldText}>Terceira Outorgante:</Text> <Text>{studentName}</Text> com morada na <Text>{studentAdress}</Text> e com o Número de Identificação Fiscal <Text>{studentNif}</Text> com plenos poderes para o ato, doravante designada como terceira outorgante.
                    {"\n"}
                    {"\n"}
                    Acordam, tendo em vista uma aproximação reciprocamente benéfica, livremente e de boa fé, celebrar o presente contrato de estágio nos seguintes termos:
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Primeira
                    </Text>
                    <Text>
                        (Âmbito)
                    </Text>
                </View>
                <Text style={styles.text}>
                    O presente Contrato visa definir os termos da colaboração entre os outorgantes, tendo como objetivo principal a promoção de um estágio curricular, pela primeira outorgante, para a terceira outorgante.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Segunda
                    </Text>
                    <Text>
                        (Compromissos da Primeira Outorgante)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. A primeira outorgante compromete-se a aceitar a terceira outorgante, para a realização de um estágio curricular de, aproximadamente, <Text>{internshipHoursDuration}</Text> horas nas suas instalações.
                    {"\n"}
                    2. A primeira outorgante compromete-se a preencher o documento de avaliação final do estágio curricular que se encontra na posse da terceira outorgante, sendo, o respetivo preenchimento, obrigatório para a atribuição de uma nota final e para o encerramento do processo de estágio.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Terceira
                    </Text>
                    <Text>
                        (Compromissos da Segunda Outorgante)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. A terceira outorgante, durante a formação, obteve os conhecimentos necessários para realizar um estágio curricular de, aproximadamente, <Text>{internshipHoursDuration}</Text> horas  nas instalações da primeira outorgante, com início no dia <Text>{internshipStartDate}</Text>
                    {"\n"}
                    2. A segunda outorgante garante à terceira outorgante um seguro de acidentes pessoais (apólice n.º 8 252 528- Seguro da Lusitânia Seguros, com cobertura conforme Anexo I), até ao término do estágio curricular.
                    A segunda outorgante não se responsabiliza por quaisquer danos materiais produzidos pela terceira outorgante.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Quarta
                    </Text>
                    <Text>
                        (Compromissos da Terceira Outorgante)
                    </Text>
                </View>
                <Text style={styles.text}>
                    1. A terceira outorgante deverá cumprir com os deveres estipulados no artigo 10.º do Regulamento da Atividade Formativa (artigo em anexo- Anexo II).
                    {"\n"}
                    2. A primeira e a segunda outorgantes poderão cancelar o estágio curricular antes da sua conclusão, quando a terceira outorgante não cumpra com os deveres estipulados no artigo 10.º do presente regulamento.
                    {"\n"}
                    3. No final do estágio, a terceiro outorgante compromete-se a entregar a ficha de “avaliação do estágio por parte da entidade de estágio” à primeira outorgante, para que esta preencha o respetivo documento e para que o formando possa ser avaliado pelo seu desempenho.
                    {"\n"}
                    4. A terceira outorgante compromete-se a zelar pelos materiais e equipamentos da primeira outorgante, utilizados durante o estágio, no âmbito das funções desempenhadas.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Quinta
                    </Text>
                    <Text>
                        (Remuneração da terceira outorgante)
                    </Text>
                </View>
                <Text style={styles.text}>
                    Não está previsto qualquer remuneração ou contrapartida financeira à terceira outorgante, durante o estágio curricular, salvo deliberação em contrário da primeira outorgante.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Sexta
                    </Text>
                    <Text>
                        (Tratamento dos dados pessoais do formando/estagiário)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. As Outorgantes são Responsáveis Conjuntos pelo tratamento de dados pessoais, nos termos do art.º 26.º do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016 (“Regulamento Geral da Proteção de Dados” ou “RGPD”), porquanto determinam conjuntamente as finalidades e os meios do tratamento dos dados pessoais dos seus titulares.
                {"\n"}
                2. Os tipos de dados, que serão tratados exclusivamente para concretizar o objeto do contrato de estágio serão <Text style={styles.boldText}>elementos de identificação e credenciais de estágio curricular </Text>, designadamente: nome de estagiários, identificação civil, função desempenhada durante o estágio, endereço de e-mail e contactos telefónicos.
                {"\n"}
                3. A Primeira Outorgante compromete-se a não tratar dados pessoais obtidos no âmbito do presente contrato para qualquer outra finalidade para além da respeitante ao mesmo.
                {"\n"}
                4. As Outorgantes assumem a responsabilidade perante os titulares de dados cujos dados pessoais as mesmas tratem, sendo responsáveis únicas pela forma como os dados são tratados na sua esfera e por eventuais violações de dados que ocorram, não podendo responsabilizar a Outorgante não infratora, salvo se existindo responsabilidade comparticipada.
                {"\n"}
                5. Obrigam-se também as Outorgantes a assegurar o exercício dos direitos do titular dos dados e a cumprir os deveres de informação a que está obrigada, em especial a fornecer um ponto de contacto para o efeito.
                {"\n"}
                6. Uma vez que os titulares de dados têm a faculdade de exercer os respetivos direitos em relação a cada uma das Outorgantes, nos termos do art.º 26.º, n.º 3 do RGPD, se aqueles o fizerem junto da Primeira Outorgante, esta informará a Segunda Outorgante.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Sétima
                    </Text>
                    <Text>
                        (Covid-19)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. A primeira outorgante compromete-se a garantir as condições necessárias para receber a terceira outorgante, segundo as orientações do Governo/Direção Geral da Saúde e de outras entidades com poder regulamentar resultantes da situação pandémica do Covid-19.
                    2. É obrigatório, por parte da terceira outorgante, o uso de máscara, durante o estágio curricular, bem como desinfetar as mãos, pelo menos, à entrada e saída da primeira outorgante.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Oitava
                    </Text>
                    <Text>
                        (Interpretação e Integração)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. Quaisquer dúvidas de interpretação do presente contrato serão dirimidas por acordo entre ambas as partes.
                    {"\n"}
                    2. Na circunstância de se verificar um ponto omisso, a situação será resolvida por acordo entre ambas as partes, sendo este acordo norteado pelo disposto no artigo 239. ° do Código Civil.
                </Text>
                <View style={styles.contractItems}>
                    <Text>
                        Cláusula Nona
                    </Text>
                    <Text>
                        (Vigência)
                    </Text>
                </View>
                <Text style={styles.contracItemsDescription}>
                    1. O presente Contrato é válido a partir do momento da sua assinatura até ao final do estágio.
                    {"\n"}
                    2. O Contrato pode ser alvo de revisão do clausulado requerendo-se para tal o acordo entre as outorgantes.
                </Text>
                <View style={styles.text}>
                    <Text style={styles.text}>
                        _____________________, _____ de _______________ de <Text>{moment().format('YYYY')}</Text>
                    </Text>
                    <Text style={styles.text}>
                        Feito em três vias, devidamente rubricadas e assinadas, de igual valor e conteúdo.
                    </Text>
                    <Text style={styles.text}>
                        PRIMEIRA OUTORGANTE:
                        {"\n"}
                        {"\n"}
                        ___________________________________________________________________________
                        {"\n"}
                        (Representante da Primeira Outorgante)
                    </Text>
                    <Text style={styles.text}>
                        SEGUNDA OUTORGANTE:
                        {"\n"}
                        {"\n"}
                        ___________________________________________________________________________
                        {"\n"}
                        (Representante da Segunda Outorgante)
                    </Text>
                    <Text style={styles.text}>
                        TERCEIRA OUTORGANTE:
                        {"\n"}
                        {"\n"}
                        ___________________________________________________________________________
                        {"\n"}
                        (Terceira Outorgante)
                    </Text>
                </View>
                <Text style={styles.pagination} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>

    )

}



// Register font => to refactory later
Font.register({ family: 'OpenSansRegular', src: OpenSansRegular });
Font.register({ family: 'OpenSansBold', src: OpenSansBold });
Font.register({ family: 'OpenSansItalic', src: OpenSansItalic });

const styles = StyleSheet.create(documentStyles)