import React from "react";
import DetailView from "../layout/Detail/DetailView";
import AddForm from "../layout/forms/AddForm";
import EditForm from "../layout/forms/EditForm";
import ListView from "../layout/ListView";
import DescriptionIcon from "@material-ui/icons/Description";
import { createContractToSign, sendContractToSign, remindSignature } from "../../redux/actions/documentsToSign";
import { createSignupDeclaration, createFrequencyDeclaration } from "../../redux/actions/documents/contract";
import { connect } from "react-redux";
import { Contrato, FormacaoModularContrato, Profiles } from "../../utils/knackKeys";

// SHARED CONSTANTS

const object = "object_6";
const objectLabel = "Contrato";
const objectLabelSingular = "Contrato";
const objectLabelPlural = "Contratos";
const listUrl = "/contratos";
const icon = (
  <DescriptionIcon
    fontSize="large"
    style={{ marginRight: 16, color: "#148FF6" }}
  />
);
const userPermissionsField = "field_432";

let DetailContract = (props) => {
  const documentActions = [
    {
      type: "digital_contrat",
      title: "Começar assinatura do contrato",
      documentFunction: props.createContractToSign,
    },
    {
      type: "remind_signature",
      title: "Reenviar email para assinatura",
      documentFunction: props.remindSignature,
    }
  ]

  return (
    <DetailView
      object={object}
      objectLabelSingular={objectLabelSingular}
      objectLabelPlural={objectLabelPlural}
      icon={icon}
      id={props.match.params.id}
      scene={Contrato.details.scene}
      view={Contrato.details.view}
      sceneEdit={"scene_98"}
      viewEdit={"view_261"}
      deletable={true}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: [
          "profile_27",
          "profile_5",
          "profile_21",
          "profile_20",
        ],
        editRolesPermissions: ["profile_21", "profile_20"],
        documentsRolesPermissions: [],
        digitalDocumentsRolesPermissions: ["profile_20"],
      }}
      tabs={[
        {
          title: "Formação Modular",
          position: 1,
          conditionalDisplay: {
            isActive: true,
            object: Contrato.object,
            field: Contrato.fields.FORMACAO_MODULAR,
            value: "Sim",
          },
          params: {
            object: FormacaoModularContrato.object,
            objectLabelPlural: "Formacao Modular Contrato",
            objectLabelSingular: "Formacao Modular Contrato",
            slug: "formacao-modular-contrato",
            parentListUrl: listUrl,
            isDetail: true,
            scene: FormacaoModularContrato.details.scene,
            view: FormacaoModularContrato.details.view,
            sceneEdit: FormacaoModularContrato.edit.scene,
            viewEdit: FormacaoModularContrato.edit.view,
            pinned: ["Abrir"],
            uploadToFolderField: FormacaoModularContrato.fields.CUSTOM_FOLDER,
            userPermissions: {
              userPermissionsField: "field_455",
              viewRolesPermissions: [Profiles.ORIENTADOR_PEDAGOGICO, Profiles.ALUNO],
              tabRolesPermissions: [Profiles.ORIENTADOR_PEDAGOGICO, Profiles.ALUNO],
              clickTableRolesPermissions: [],
              editRolesPermissions: [],
              ignoreUserPermissionsField: true
            },
            extraConfigs: {
              showCreateButton: "no",
              showUpdateButton: true,
            },
            filterByConnectedField: {
              connected_field: FormacaoModularContrato.fields.CONTRATO,
              id: props.match.params.id,
              identifierNameField: "",
              parentsConnectedField: Contrato.fields.FORMACAO_MODULAR_CONTRATO,
            },
          },
        }
      ]}
      documents={[
        {
          type: "signup_declaration",
          title: "Criar Declaração de Inscrição",
          fileName: "Declaracao_Inscricao.pdf",
          documentFunction: props.createSignupDeclaration,
        },
        {
          type: "frequency_declaration",
          title: "Criar Declaração de Frequência",
          fileName: "Declaracao_Frequencia.pdf",
          documentFunction: props.createFrequencyDeclaration,
        },
      ]}
      documentsToSign={documentActions}
    />
  );
};

const mapDispatchToProps = {
  createContractToSign,
  sendContractToSign,
  remindSignature,
  createSignupDeclaration,
  createFrequencyDeclaration,
};
DetailContract = connect(null, mapDispatchToProps)(DetailContract);

const FormAddContract = () => (
  <AddForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    scene={"scene_97"}
    view={"view_135"}
    userPermissions={{
      userPermissionsField: userPermissionsField,
      viewRolesPermissions: ["profile_5", "profile_21", "profile_20"],
    }}
  />
);

const FormEditContract = (props) => {
  return (
    <EditForm
      objectLabel={objectLabel}
      object={object}
      listUrl={listUrl}
      id={props.match.params.id}
      scene={"scene_98"}
      view={"view_261"}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: ["profile_5", "profile_21", "profile_20"],
      }}
    />
  );
};

const ListContracts = () => {
  return (
    <ListView
      object={object}
      objectLabelPlural={objectLabelPlural}
      objectLabelSingular={objectLabelSingular}
      scene={Contrato.list.scene}
      view={Contrato.list.view}
      icon={icon}
      pinned={["Número do contrato", "Aluno", "Abrir", "Estado do contrato"]}
      userPermissions={{
        userPermissionsField: userPermissionsField,
        viewRolesPermissions: ["profile_5"],
      }}
      extraConfigs={{
        showCreateButton: "no",
        // filters: {
        //   dropDown: [
        //     { label: "Seguro ativo", value: "Seguro ativo,field_65" },
        //     { label: "Seguro não ativo", value: "Seguro Não ativo,field_65" },
        //   ],
        // },
        actionsMenu: [
          {
            name: "Enviar e-mail com apresentação e acesso ao Puzzle",
            customActionId: "emailWithAccessAndClassInformation",
          },
          {
            name: "Enviar e-mail com acesso ao Puzzle",
            customActionId: "emailWithAccessInformation",
          },
          {
            name: "Enviar SMS em Massa",
            customActionId: "sendMassSMS",
          },
          {
            name: "Enviar Email em Massa",
            customActionId: "sendMassEmail",
          },
          {
            name: "Gerar Ficheiro de Débitos",
            customActionId: "generateDebitsFile",
          },
        ],
      }}
    />
  );
};

export { DetailContract, FormAddContract, FormEditContract, ListContracts };
